<template>
  <div class="bt-transaksi pa-5">
    <h6 class="text-h5 font-weight-bold indigo--text text--lighten-1 d-flex align-center">
      <div class="rounded-pill indigo lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3">
        <v-icon small color="white">mdi-account-multiple-minus</v-icon>
      </div>
      Profil Anggota
    </h6>
    <div>
      <v-select solo label="Majelis" class="mb-3 mt-3" hide-details :items="opt.rembug" v-model="form.data.kode_rembug"
        @change="getAnggota()" />
      <v-select solo label="Pilih Anggota" class="mb-3" hide-details :items="opt.anggota" v-model="form.data.no_anggota"
        @change="setAnggota()" />
    </div>
    <v-container class="pa-0">
      <v-card class="white elevation-3 rounded-lg pa-3 mb-3">
        <v-row>
          <v-col cols="12">
            <v-text-field color="black" autocomplete="off" hide-details outlined :value="form.data.no_anggota"
              label="No. Anggota" disabled />
          </v-col>
          <v-col cols="12">
            <v-text-field color="black" autocomplete="off" hide-details outlined :value="form.data.no_ktp"
              label="No KTP" disabled />
          </v-col>
          <v-col cols="12">
            <v-text-field color="black" autocomplete="off" hide-details outlined :value="form.data.alamat"
              label="Alamat" disabled />
          </v-col>
          <v-col cols="12">
            <v-text-field color="black" autocomplete="off" hide-details outlined :value="thousandMask(form.data.simpok)"
              label="Simpanan Pokok" disabled />
          </v-col>
          <v-col cols="12">
            <v-text-field color="black" autocomplete="off" hide-details outlined :value="thousandMask(form.data.simsuk)"
              label="Simpanan Wajib" disabled />
          </v-col>
          <v-col cols="12">
            <v-text-field color="black" autocomplete="off" hide-details outlined :value="thousandMask(form.data.simwa)"
              label="Simpanan Sukarela" disabled />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h1 class="text-center">Tabungan Berencana</h1>
            <v-data-table :headers="table_tabungan.headers" :items="table_tabungan.contents" :items-per-page="5"
              class="elevation-1"></v-data-table>
          </v-col>
          <v-col cols="12">
            <h1 class="text-center">Tabungan Pembiayaan</h1>
            <v-data-table :headers="table_pembiayaan.headers" :items="table_pembiayaan.contents" :items-per-page="5"
              class="elevation-1"></v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import helper from "@/utils/helper";
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
export default {
  name: "ProfilAnggota",
  components: {},
  data() {
    return {
      table_tabungan: {
        headers: [
          {
            text: 'No Rekening',
            align: 'start',
            sortable: false,
            value: 'no_rekening',
          },
          { text: 'Produk', value: 'nama_produk' },
          { text: 'Tanggal Buka', value: 'tanggal_buka' },
          { text: 'Jangka Waktu', value: 'jangka_waktu' },
          { text: 'Jatuh Tempo', value: 'jatuh_tempo' },
          { text: 'Saldo', value: 'saldo' },
          { text: 'Status', value: 'status_rekening' },
        ],
        contents: [],
      },
      table_pembiayaan: {
        headers: [
          {
            text: 'No Rekening',
            align: 'start',
            sortable: false,
            value: 'no_rekening',
          },
          { text: 'Produk', value: 'nama_produk' },
          { text: 'Pokok', value: 'pokok' },
          { text: 'Margin', value: 'margin' },
          { text: 'Tanggal Cair', value: 'tanggal_akad' },
          { text: 'Jangka Waktu', value: 'jangka_waktu' },
          { text: 'Saldo Pokok', value: 'saldo_pokok' },
          { text: 'Saldo Margin', value: 'saldo_margin' },
          { text: 'Saldo Catab', value: 'saldo_catab' },
          { text: 'Status', value: 'status_rekening' },
        ],
        contents: [],
      },
      form: {
        data: {
          kode_rembug: null,
          no_anggota: null,
          simsuk: 0,
          simpok: 0,
          simwa: 0,
          list_pembiayaan: [],
          list_tabungan: [],
        }
      },
      opt: {
        rembug: [],
        anggota: []
      },
      alert: {
        show: false,
        msg: ''
      },
      loading: false
    }
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...helper,
    async getRembug() {
      try {
        this.loading = true;
        this.opt.rembug = [];

        const {
          kode_cabang = null
        } = this.user;

        let payload = new FormData();
        payload.append("kode_cabang", kode_cabang);

        const response = await services.rembugRead(payload, this.user.token);
        if (response.status === 200) {
          response.data.data.map((item) => {
            this.opt.rembug.push({
              text: item.nama_rembug,
              value: item.kode_rembug,
            });
          });
        } else {
          this.alert = {
            show: true,
            msg: response.data.message || null,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      } finally {
        this.loading = false;
      }
    },
    async getAnggota() {
      try {
        this.loading = true;
        this.opt.anggota = []

        const {
          kode_cabang = null
        } = this.user;

        let payload = new FormData()
        payload.append("kode_cabang", kode_cabang);
        payload.append('kode_rembug', this.form.data.kode_rembug)
        payload.append('no_anggota', null);

        const response = await services.anggotaRead(payload, this.user.token)
        if (response.status === 200) {
          response.data.data.map((item) => {
            this.opt.anggota.push({
              value: item.no_anggota,
              text: item.nama_anggota,
              data: item
            });
          });
        } else {
          this.alert = {
            show: true,
            msg: response.data.message || null,
          }
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error
        }
      } finally {
        this.loading = false;
      }
    },
    async setAnggota() {
      try {
        this.loading = true;
        let payload = new FormData()
        payload.append('no_anggota', this.form.data.no_anggota);
        const response = await services.getProfilAnggota(payload, this.user.token)
        if (response) {
          if (response.status === 200) {
            const {
              data,
            } = response.data;

            this.form.data.no_anggota = data.no_anggota;
            this.form.data.no_ktp = data.no_ktp;
            this.form.data.alamat = data.alamat;
            this.form.data.simpok = data.simpok;
            this.form.data.simsuk = data.simsuk;
            this.form.data.simwa = data.simwa;

            let tabunganContents = [];
            if (data.tabungan.length == 0) {
              tabunganContents = [];
            } else {
              data.tabungan.forEach(item => {
                tabunganContents.push({
                  no_rekening: item.no_rekening,
                  nama_produk: item.nama_produk,
                  tanggal_buka: item.tanggal_buka && item.tanggal_buka != '' && item.tanggal_buka != null ? this.formatDate(item.tanggal_buka) : null,
                  jangka_waktu: item.jangka_waktu,
                  jatuh_tempo: item.jatuh_tempo && item.jatuh_tempo != '' && item.jatuh_tempo != null ? this.formatDate(item.jatuh_tempo) : null,
                  saldo: this.thousandMask(item.saldo),
                  status_rekening: item.status_rekening,
                });
              });
            }
            this.table_tabungan.contents = tabunganContents;

            let pembiayaanContents = [];
            if (data.pembiayaan.length == 0) {
              pembiayaanContents = [];
            } else {
              data.pembiayaan.forEach(item => {
                pembiayaanContents.push({
                  no_rekening: item.no_rekening,
                  nama_produk: item.nama_produk,
                  pokok: this.thousandMask(item.pokok),
                  margin: this.thousandMask(item.margin),
                  tanggal_akad: item.tanggal_akad && item.tanggal_akad != '' && item.tanggal_akad != null ? this.formatDate(item.tanggal_akad) : null,
                  jangka_waktu: item.jangka_waktu,
                  saldo_pokok: this.thousandMask(item.saldo_pokok),
                  saldo_margin: this.thousandMask(item.saldo_margin),
                  saldo_catab: this.thousandMask(item.saldo_catab),
                  status_rekening: item.status_rekening,
                });
              });
            }
            console.log({ pembiayaanContents, tabunganContents });
            this.table_pembiayaan.contents = pembiayaanContents;
          } else {
            this.alert = {
              show: true,
              msg: response.data.message || null,
            }
          }
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error
        }
      } finally {
        this.loading = false;
      }
    },
    clearForm() {
      this.form.data.no_anggota = null;
      this.form.data.no_ktp = null;
      this.form.data.alamat = null;
      this.form.data.simpok = 0
      this.form.data.simsuk = 0
      this.form.data.simwa = 0
      this.table_tabungan.contents = [];
      this.table_pembiayaan.contents = [];
    },
  },
  mounted() {
    this.getRembug();
  },
};
</script>
